export default class extends Controller {

  search(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const value = event.target.value;
      const param = event.target.getAttribute('data-filter-query-param');
      const url = this.updateQueryParam(param, value)
      Turbo.visit(url.toString());
    }, 1000);
  }

  applyFilter(){
    let value;
    if (event.currentTarget.type == 'checkbox') {
      value = event.currentTarget.checked
    } else {
      value = event.currentTarget.value
    }
    const url = this.updateQueryParam(event.params.query, value)
    Turbo.visit(url.toString());
  }

  updateQueryParam(key, value){
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      if (value === "All" || value === '') {
          params.delete(key);
      } else {
          params.set(key, value);
      }
      url.search = params.toString();
      return url.toString();
  }

  clearFilters(){
    const url = new URL(window.location.href);
    url.search = ''
    Turbo.visit(url.toString());
  }

  clearHotelFilters(){
    const url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    params.delete('hotel_ids'); // Remove the 'hotel_ids' parameter

    url.search = params.toString();
    Turbo.visit(url.toString());
  }

  applySort(){
    var sort;
    if (event.currentTarget.dataset.sortDir === '') {
         sort = `${event.currentTarget.dataset.sortKey}:desc`;
    } else if (event.currentTarget.dataset.sortDir.split(":")[1] === 'asc') {
         sort = `${event.currentTarget.dataset.sortKey}:desc`;
    } else {
         sort = `${event.currentTarget.dataset.sortKey}:asc`;
    }
    console.log(sort);
    const url = this.updateQueryParam('sort', sort)
    Turbo.visit(url.toString());
  }
}
