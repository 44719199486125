export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    this.picker = new EmojiButton()
    this.picker.on('emoji', emoji => {
      this.inputTarget.value = this.inputTarget.value + emoji.emoji
    })
  }

  toggle(event) {
    event.preventDefault()
    this.picker.togglePicker(event.target)
  }
}
